import React from 'react';
import Social from './Social';
import { Link } from 'react-router-dom';

class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			stage: 0
		};
	}

	componentDidMount() {

	}

	render() {
		return (
			<header className="site-head">
				<div className="main-container">
					<div className="site-mast">
						<div className="site-mast-left">
							<Link aria-current="page" to="/">
								<img className="site-logo" src="img/logo.png" alt="Ghost &amp; Gatsby" />
							</Link>
						</div>
						<div className="site-mast-right">
							<Social />
						</div>
					</div>
					<div className="site-banner">
						<h1 className="site-banner-title">SHAPE DECOR</h1>
						<p className="site-banner-desc">Home decor - Wall decor - Letters decor</p>
					</div>
					<nav className="site-nav">
						<div className="site-nav-left">
							<Link className="site-nav-item" to="/">Fonts</Link>
							<Link className="site-nav-item" to="/products">Products</Link>
						</div>
						<div className="site-nav-right">
							<Link className="site-nav-item" to="/about">About</Link>
						</div>
					</nav>
				</div>
			</header>
		);
	}
}

export default Header;
