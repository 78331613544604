import React from 'react';
import Layout from '../components/Layout';

const About = ({ meta }) => {
	const intro = meta.web.intro;
	return (
		<Layout>
			<div className="container about">
				<h1 style={{ textAlign: 'center' }}>Giới thiệu</h1>
				{intro.split("\n").map((para, index) => (
					<p key={index}>{para}</p>
				))}
			</div>
		</Layout>
	);
}

export default About;
