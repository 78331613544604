import React from 'react';

const ProductElem = ({ product }) => {
	return (
		<div className="col-md-6 col-12">
			<div className="post-content">
				<img alt={product.title} className="img-content" src={"/images/" + product.image} />
				<h4 className="header-content">{product.title}</h4>
				<p className="text-content">{product.content.split("\n").map(content => (
					<span key={content}>{content}<br /></span>
				))}</p>
			</div>
		</div>
	);
}

export default ProductElem;
