import React from 'react';
import Layout from '../components/Layout';
import ProductElem from '../components/ProductElem';

const Products = ({ meta }) => {
	const { products } = meta.web;
	return (
		<Layout>
			<div className="containe products">
				<div className="row">
					{ (products || []).map(product => (
						<ProductElem key={product.id} product={product} />
					))}
				</div>
				<div className="dat_hang_container">
					<img alt="Cach Dat Hang" src="/images/cach_dat_hang.jpg" />
				</div>
			</div>
		</Layout>
	);
}
export default Products;
