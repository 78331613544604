import React, { Fragment } from 'react';

const Social = () => {
	return (
		<Fragment>
			<a href="https://shopee.vn/nctlinh96" className="site-nav-item" target="_blank" rel="noopener noreferrer">
				<img className="social-img" src="/img/icons/shopee.png" alt="Shopee Shape decor" />
			</a>
			<a href="https://www.facebook.com/Shapedecorvietnam" className="site-nav-item" target="_blank" rel="noopener noreferrer">
				<img className="site-nav-icon" src="/img/icons/facebook.svg" alt="Facebook Shape decor" />
			</a>
			<a href="https://instagram.com/shape_decor" className="site-nav-item" target="_blank" rel="noopener noreferrer">
				<img className="social-img" src="/img/icons/instagram.png" alt="Facebook Shape decor" />
			</a>
		</Fragment>
	);
}

export default Social;
