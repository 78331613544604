import React from 'react';

class FontElem extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { family } = this.props.font;
	
		return (
			<div className="font-container">
				<h2 className="font-title">{family}</h2>
				<div className="img-container">
					<span className="font-sample" style={{
						fontFamily: `"${family}"`,
						fontSize: this.props.size
					}}>{this.props.text || family}</span>
				</div>
				<div className="font-info">
					<svg viewBox="0 0 16 16"
						version="1.1" height="16" aria-hidden="true"
						className={"clickable style-dot active"}
					>
						<path d="M0 8c0-2.2 1.8-4 4-4s4 1.8 4 4-1.8 4-4 4-4-1.8-4-4z"></path>
					</svg>
					<span className="buffer1-right show-on-hover">Regular</span>
				</div>
			</div>
		);
	}
}

export default FontElem;
