import React, { Component } from 'react';
import Fonts from './pages/Fonts';
import Products from './pages/Products';
import About from './pages/About';
import Reload from './pages/Reload';

import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect
} from "react-router-dom";

const meta = {
  "fonts": {
    "itemPerPage": 10
  },
  "web": {
    "texts": {
      "text_1": "Xin chào"
    },
    "images": {
      
    },
    "products": [
      {
        "id": 1,
        "title": "Chữ gỗ để bàn và chữ gỗ treo tường",
        "content": "+  Phù hợp với các phong cách vintage\r\n+  Có nhiều độ dày từ 0,5cm đến 1,2cm\r\n+  Có thể sơn màu – độ bền cao\r\n+  Có nhiều màu gỗ mộc đa dạng \r\n+ Có thể mix thành các bảng gỗ theo yêu cầu",
        "image": "image_1.jpg"
      },
      {
        "id": 2,
        "title": "Chữ Mica ",
        "content": "+ Phù hợp với các phong cách  hiện đại nổi bật.\r\n+ Có nhiều độ dày từ 0,3cm đến 0,8 cm\r\n+ Có nhiều màu sắc cơ bản và có thể sơn màu đa dạng\r\n+ Có thể mix thành các bảng mica để treo theo yêu cầu",
        "image": "image_2.jpg"
      },
      {
        "id": 3,
        "title": "Chữ alu tráng gương",
        "content": "+ Phù hợp với các phong cách  hiện đại nổi bật.\r\n+ Có nhiều độ dày ~ 0,4cm \r\n+ Có thể kê đế dày 1cm bằng fomex trắng \r\n+ Có  một màu vàng đồng\r\n+ Có thể mix thành các bảng alu + mica để treo theo yêu cầu",
        "image": "image_3.jpg"
      },
      {
        "id": 4,
        "title": "Chữ fomex",
        "content": "+ Phù hợp với các phong cách  hiện đại nổi bật.\r\n+ Giá thành rẻ\r\n+ Có nhiều độ dày từ 0,5cm đến 2cm\r\n+ Có thể sơn màu đa dạng",
        "image": "image_4.jpg"
      },
      {
        "id": 5,
        "title": "Biển trang trí theo yêu cầu",
        "content": "+ Có thể đặt theo nhiều kiểu mẫu, và Sáng tạo theo ý thích\r\n+ Có thể dễ dàng thay đổi vị trí treo tường và không cần keo dính \r\n+ Màu sắc mix đa dạng\r\n+ Có nhiều độ dày từ 0,5cm đến 2 cm",
        "image": "image_5.jpg"
      }
    ],
    "intro": "Nhà cửa một nửa tâm hồn! Những sáng tạo đầy tính tỉ mỉ và trau chuốt bởi đội ngũ Shape Decor sẽ khiến bạn hài lòng. Nhằm tô điểm cho không gian sống, biến hóa từ bức tường trống trở nên khác biệt. Hay khiến khách hàng dễ dàng ghi nhớ tên thương hiệu của bạn chỉ trong vài giây đầu chạm mắt. Tất cả điều gì khó đã có Shape Decor lo: SHAPE DECOR là đơn vị chuyên sản xuất, cung cấp các dòng sản phẩm chữ nổi dán tường trang trí nghệ thuật bằng nhiều chất liệu khác nhau vô cùng tiện lợi và chất lượng tuỳ theo nhu cầu và mục đích sử dụng của khách hàng. Phong cách cá nhân riêng biệt, độc đáo. Chất lượng tốt với chi phí hợp lí nhất. Cập nhật các xu hướng trang trí mới nhất. Đội ngũ nhân viên trẻ trung năng động của SHAPE luôn sẵn sàng đồng hành cùng bạn.\r\n - Shape Decor cung cấp các sản phẩm chữ nổi dán tường, chữ để bàn chất liệu gỗ , fomex , alu, mica, khung ảnh nghệ thuật, quà tặng ý nghĩa, độc đáo...  \r\n - Tư vấn trang trí chữ nổi decor cho showroom, shop thời trang, studio, spa, quán coffee , phòng ngủ, phòng khách, studio... Shape Decor thay bạn thể hiện một nửa tâm hồn, gói gọn trong những món đồ trang trí nghệ thuật. Mời bạn ghé thăm và cùng cảm nhận!"
  },
  "values": {
    "minValue": 10,
    "maxValue": 72
  }
};

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			error: false,
			meta: meta,
      fonts: []
		};

		this.loadedFonts = new Set();
	}

  componentDidMount() {
    fetch("fonts.json")
    .then(res => res.json())
    .then(res => {
      this.setState({ fonts: res });
    })
    .catch(err => {
      console.error(err);
    });
  }

  loadFont(font) {
    if (this.loadedFonts.has(font)) {
      return;
    }

    this.loadedFonts.add(font);
    // Load font family by FontFace API
    const myFont = new FontFace(font.family, `url("${font.url}")`);
    myFont.load().then(() => {
      document.fonts.add(myFont);
    });
  }

	render() {
		if (this.state.loading) {
			return <div>Loading</div>;
		}
		if (this.state.error) {
			return <div>Error</div>;
		}
		return (
			<Router>
				<Switch>
					<Route exact path="/">
						<Fonts
              meta={this.state.meta}
              fonts={this.state.fonts}
              load={font => this.loadFont(font)}
            />
					</Route>
					<Route exact path="/products">
						<Products meta={this.state.meta} />
					</Route>
					<Route exact path="/about">
						<About meta={this.state.meta} />
					</Route>
					<Route exact path="/secret-reload">
						<Reload />
					</Route>
					<Route path="*">
						<Redirect to="/" />
					</Route>
				</Switch>
			</Router>
		);
	}
}

export default App;
