import React from 'react';

class FontPaging extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			pages: this.generatePages(props.total || 0)
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.total !== this.props.total) {
			this.setState({ pages: this.generatePages(this.props.total) });
		}
	}

	generatePages(total) {
		if (total <= 1) return [];
		const pages = [];
		for (let i = 0; i < total; i++) {
			pages.push(i);
		}
		return pages;
	}

	render() {
		const { page } = this.props;
		if (this.state.pages.length === 0) return <div></div>;
		return (
			<div className="paging">
				{ this.state.pages.map(p => (
					<button
						key={p}
						className={"paging-item " + ((p === page) ? "active" : "")}
						onClick={e => {
							e.preventDefault();
							this.props.onChangePage(p);
						}}
					>{p + 1}</button>
				))}
			</div>
		);
	}
}

export default FontPaging;
