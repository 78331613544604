import React, { Component } from 'react';

class Reload extends Component {
	constructor(props) {
		super(props);
		this.state = {
			username: "",
			password: ""
		};
	}

	reloadFonts() {
		this.doAction('restart');
	}

	reloadConfigure() {
		this.doAction('reload');
	}

	doAction(action) {
		const { username, password } = this.state;
		console.log("Reload....", username, password);
		const origin = new URL(window.origin);
		origin.port = "8081";
		const url = `${origin.toString()}${action}?username=${username}&password=${password}`;
		console.log(url);
		fetch(url)
		.then(response => response.json())
		.then(data => {
			alert(data.msg);
		})
		.catch(err => {
			console.error(err);
			alert("Error occurs");
		});
	}

	render() {
		return (
			<div>
				<input placeholder="Username"
					type="text"
					value={this.state.username}
					onChange={e => this.setState({ username: e.target.value })}
				/>
				<input placeholder="Password"
					type="password"
					value={this.state.password}
					onChange={e => this.setState({ password: e.target.value })}

				/>
				<button onClick={() => this.reloadFonts()}>Reload Fonts</button>
				{/*<button onClick={() => this.reloadConfigure()}>Reload data.xlsx</button>*/}
			</div>
		);
	}
}

export default Reload;
