import React from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

class FontAction extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			value: Math.round(this.convertSizeToValue(props.size || 0))
		};
	}

	convertValueToSize(value) {
		// const { minValue, maxValue } = this.props.meta.values;
		// return minValue + (maxValue - minValue) * (value / 100);
		return value;
	}

	convertSizeToValue(size) {
		// const { minValue, maxValue } = this.props.meta.values;
		// return 100 * (size - minValue) / (maxValue - minValue);
		return size;
	}

	finishChange() {
		const { value } = this.state;
		const size = this.convertValueToSize(value);
		this.props.onChangeSize(Math.round(size));
	}

	componentDidUpdate(prevProps) {
		if (prevProps.size !== this.props.size) {
			this.setState({
				value: Math.round(this.convertSizeToValue(this.props.size || 0))
			});
		}
	}

	render() {
		const { minValue, maxValue } = this.props.meta.values;
		return (
			<div className="action-bar">
				<div className="action-component">
					<input
						placeholder="Gõ tiếng Việt không dấu"
						type="text" className="search-font"
						value={this.props.text}
						onChange={e => this.props.onChange(e.target.value)}
					/>
				</div>
				<div className="action-component action-slider">
					<Slider
						min={minValue}
						max={maxValue}
						value={this.state.value}
						onChange={value => this.setState({ value })}
						onAfterChange={() => this.finishChange()}
					/>
				</div>
			</div>
		);
	}
}

export default FontAction;
