import React from 'react';
import Social from './Social';

const Footer = () => {
	return (
		<footer className="site-foot">
			<div className="site-foot-nav main-container">
				<div className="site-foot-nav-left">
					<div style={{ marginLeft: -8 }}>
						<Social />
					</div>
					<div>© 2020 - Shape Decor</div>
				</div>
				<div className="site-foot-nav-right">
					<div>
						<h4>LIÊN HỆ</h4>
						<p>Store: 8A ngõ 667/2 Nguyễn Văn Cừ Long Biên Hà Nội</p>
						<p>Số điện thoại: 0975703851</p>
						<p>Email: shapedecorthietke01@gmail.com</p>
						<p>Website: shapedecorvn.com</p>
						<p>Fanpage: Shape Decor - Chữ trang trí</p>
					</div>
				</div>
			</div>
		</footer>
	);
}

export default Footer;

// Ngoài ra, các bạn cũng có thể mua sản phẩm ở bất cứ đâu qua các kênh:
// Website : shapedecor.vn
// Facebook : Shape decor - chữ trang trí
// Insta : shape_decor
// Shopee: nctlinh96
// Mọi ý kiến đóng góp, thắc mắc cũng như trao đổi thông tin, Quý khách vui lòng liên hệ:
// • Số điện thoại: 0975703851
// • Email: shapedecorthietke01@gmail.com
// • Website: shapedecor.vn
// • Fanpage: Shape Decor - Chữ trang trí