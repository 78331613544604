import React, { Component } from 'react';
import Layout from '../components/Layout';
import FontElem from '../components/FontElem';
import FontAction from '../components/FontAction';
import FontPaging from '../components/FontPaging';

class Fonts extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			text: "",
			page: 0,
			size: 42,
			fonts: []
		};
	}

	componentDidMount() {
		this.changePage(0);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.fonts === this.props.fonts) {
			return;
		}

		// Reupdate
		this.changePage(this.state.page);
	}

	changePage(page) {
		const fonts = [];
		const items = this.props.meta.fonts.itemPerPage;
		const offset = items * page;
		for (let i = 0; i < items; ++i) {
			if (i + offset >= this.props.fonts.length) {
				break;
			}
			fonts.push(this.props.fonts[i + offset]);
		}

		fonts.forEach(font => this.props.load(font));
		this.setState({
			page: page,
			fonts: fonts
		});
	}

	render() {
		const { meta } = this.props;
		const fonts = this.state.fonts;

		return (
			<Layout>
				<div className="pad-20-desktop content-rounded-both">
					<FontAction
						meta={this.props.meta}
						text={this.state.text}
						onChange={text => this.setState({ text: removeVietnameseTones(text) }) }
						size={this.state.size}
						onChangeSize={size => this.setState({ size: size })}
					/>
					{ fonts.map(font => (
						<FontElem
							key={font.url}
							text={this.state.text}
							font={font}
							loading={this.state.loading}
							size={this.state.size}
						/>
					))}
					<FontPaging
						total={Math.ceil(this.props.fonts.length / meta.fonts.itemPerPage)}
						page={this.state.page}
						onChangePage={p => this.changePage(p)}
					/>
				</div>
			</Layout>
		);
	}
}

function removeVietnameseTones(str) {
	str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g,"a"); 
	str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g,"e"); 
	str = str.replace(/ì|í|ị|ỉ|ĩ/g,"i"); 
	str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g,"o"); 
	str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g,"u"); 
	str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g,"y"); 
	str = str.replace(/đ/g,"d");
	str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
	str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
	str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
	str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
	str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
	str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
	str = str.replace(/Đ/g, "D");
	// Some system encode vietnamese combining accent as individual utf-8 characters
	// Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
	str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
	str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
	// Remove extra spaces
	// Bỏ các khoảng trắng liền nhau
	str = str.replace(/ + /g," ");
	// str = str.trim();
	// Remove punctuations
	// Bỏ dấu câu, kí tự đặc biệt
	// str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g," ");
	return str;
}

export default Fonts;
